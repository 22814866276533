<template>
    <div>
        <div class="page-content-box">
            <div class="page-filter-box">
                <el-row :gutter="16">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-input v-model="params.queryValue" class="input-with-select" size="small">
                            <el-select v-model="params.queryKey" slot="prepend" placeholder="请选择">
                                <el-option label="众筹ID" :value="1"></el-option>
                                <el-option label="众筹名称" :value="2"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                        <el-button type="primary" @click="search" size="small">搜索</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >
                    <el-table-column prop="id" label="众筹ID"></el-table-column>
                    <el-table-column prop="title" label="众筹名称" min-width="200" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="modifyTime" label="最近编辑时间" min-width="146"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="70">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="edit(scope.row.id)"
                                >继续完善</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="data" class="page-pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                pageNo:parseInt(this.$route.query.pageNo) || 1,
                pageSize: parseInt(this.$route.query.pageSize) || 15,
                queryKey:parseInt(this.$route.query.queryKey) || 1,
                queryValue:this.$route.query.queryValue || '',
            },
            listLoading: true,
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/raise/list/draft",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //编辑事件
        edit(id){
            this.$router.push({
                path: `/manage/raise/edit/step1/${id}`,
                query: {
                    redirect: this.$utils.objArgs(
                        this.$router.currentRoute.path,
                        this.params
                    ),
                },
            });
        },
    }
}
</script>